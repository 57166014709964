<template>
  <header :class="['base-header', { active: isOpen }]">
    <div class="wrapper">
      <nuxt-link :to="localePath('/')">
        <base-icon name="Logo_light" class="logo" />
      </nuxt-link>
      <nav
        class="base-header--nav"
        :class="{ 'nav-longword': locale !== 'en' }"
      >
        <nuxt-link
          class="font--b5 text-black-monochrome"
          :to="localePath('directory')"
          >{{ $t("header.h_dir") }}</nuxt-link
        >
        <nuxt-link :to="localePath('comparison')">
          <span class="compare">
            <p class="font--b5 text-black-monochrome">
              {{ $t("header.h_com") }}
            </p>
            <span v-if="isCompareBadge" class="compare-badge"></span> </span
        ></nuxt-link>
        <!-- <a
          class="font--b5 text-black-monochrome"
          href="https://rwa-estate.gitbook.io/rwa-estate"
          target="_blank"
          >{{ $t("header.h_book") }}</a -->

        <!-- <a
          class="font--b5 text-black-monochrome"
          :href="`https://www.rwa-scan.com/?lang=${locale}`"
        >
          RWA Scan
          <base-icon
            name="base/DownRight"
            size="20px"
            color="var(--black-monochrome)"
            rot="-90deg"
            style="margin-bottom: 3px"
          />
        </a> -->
        <nuxt-link
          class="font--b5 text-black-monochrome"
          :to="localePath('faq')"
          >{{ $t("common.h_faq") }}</nuxt-link
        >
        <!-- <button
          v-if="useUserStore.isInSystem"
          class="user-login"
          @click="useUserStore.userSignOut"
        >
          <p class="font--b3 text-black-monochrome">Log Out</p>
          <base-icon
            name="base/Login"
            size="20px"
            color="var(--black-monochrome)"
            filled
            style="transform: scaleX(-1)"
          />
        </button> -->
        <base-language-button />
        <div
          v-if="!useUserStore.isInSystem"
          class="auth-controls"
          :class="{ 'nav-longword': locale !== 'en' }"
        >
          <base-button
            :class="{ 'auth-controls__padding': locale === 'en' }"
            :text="$t('header.h_log')"
            variant="dark_ghost"
            size="xss"
            :font="locale === 'en' ? 'b2' : 'b5'"
            @click="debouncedLoginUser('login')"
          />
          <base-button
            :class="{ 'auth-controls__padding': locale === 'en' }"
            :text="$t('header.h_sing')"
            variant="dark"
            size="xss"
            :font="locale === 'en' ? 'b2' : 'b5'"
            @click="debouncedLoginUser('signup')"
          />
        </div>
        <div v-if="useUserStore.isInSystem" class="auth-controls">
          <base-button
            :text="$t('header.h_add')"
            variant="dark_ghost"
            size="xss"
            @click="addUnit"
          />
          <q-avatar
            size="40px"
            class="user-avatar"
            @click.stop="handleAvatarClick"
          >
            <q-img
              :src="useUserStore.avatar || defaultAvatar"
              fit="cover"
              style="max-width: 40px; height: 40px"
            />
          </q-avatar>
        </div>

        <!--        <a class="font&#45;&#45;b3 text-black-monochrome" href="/">About Us</a>-->
      </nav>
      <button
        :class="['base-header--menu-button', { active: isOpen }]"
        @click="toggleMenu"
      >
        <span />
      </button>
    </div>
    <div
      :class="['base-header--overlay', { active: isOpen }]"
      @click="toggleMenu"
    >
      <nav
        class="base-header--popup"
        :class="{ 'base-header--popup-active': isOpen }"
      >
        <nuxt-link
          class="font--h5 text-black-monochrome"
          :to="localePath('search')"
          >{{ $t("header.h_dir") }}</nuxt-link
        >
        <nuxt-link :to="localePath('comparison')">
          <span class="compare">
            <p class="font--h5 text-black-monochrome">
              {{ $t("header.h_com") }}
            </p>
          </span></nuxt-link
        >
        <!-- <a
          class="font--h5 text-black-monochrome"
          href="https://rwa-estate.gitbook.io/rwa-estate"
          target="_blank"
          >{{ $t("header.h_book") }}</a
        > -->
        <!-- <a
          class="font--h5 text-black-monochrome"
          href="https://www.rwa-scan.com/"
        >
          RWA Scan<base-icon
            name="base/DownRight"
            size="32px"
            color="var(--black-monochrome)"
            rot="-90deg"
          />
        </a> -->
        <nuxt-link
          class="font--h5 text-black-monochrome"
          :to="localePath('faq')"
          >{{ $t("common.h_faq") }}</nuxt-link
        >
        <nuxt-link
          v-if="useUserStore.isInSystem"
          class="font--h5 text-black-monochrome"
          to="/profile"
          >{{ $t("header.h_acc") }}</nuxt-link
        >

        <div v-if="!useUserStore.isInSystem" class="auth-controls">
          <base-button
            :text="$t('header.h_log')"
            variant="dark_ghost"
            size="sm"
            @click="debouncedLoginUser('login')"
          />
          <base-button
            :text="$t('header.h_sing')"
            variant="dark"
            size="sm"
            @click="debouncedLoginUser('signup')"
          />
        </div>
        <div v-if="useUserStore.isInSystem" class="auth-controls">
          <base-button
            :text="$t('header.h_add')"
            variant="dark_ghost"
            size="sm"
            @click="addUnit"
          />
        </div>
        <!--        <a class="font&#45;&#45;h5 text-black-monochrome" href="/">About Us</a>-->
      </nav>
    </div>
  </header>
</template>

<script setup lang="ts">
import { getLocalStorage } from "~/services/LocalStorage/localStorage";
import eventBus from "~/utilities/composables/eventBus";
import { PopUpServices } from "~/services/PopUp/callPopUp";
import { userStore } from "~/store/user";
import { debounce } from "lodash";
import defaultAvatar from "~/assets/img/user/defaultAvatar.svg";

const callPopUp = PopUpServices();
const useUserStore = userStore();
const localePath = useLocalePath();
const route = useRoute();
const { locale } = useI18n();
const { isMobile } = useDevice();

const isOpen = ref(false);

const isCompareBadge = ref(false);

function toggleMenu() {
  isOpen.value = !isOpen.value;
}

const addUnit = () => {
  window.open("https://t.me/rwasupport", "_blank");
};

const loginUser = async (type: "login" | "signup") => {
  callPopUp.callPopUp({
    componentName: "login-login-pop-up",
    persistent: false,
    data: type,
  });
};

const debouncedLoginUser = debounce((type: "login" | "signup") => {
  loginUser(type);
}, 300);

const handleAvatarClick = () => {
  if (route.path === "/profile") {
    eventBus.emit("open-user-from-header", isMobile);
  } else {
    navigateTo(localePath("profile"));
  }
};

onMounted(() => {
  const estateIds = getLocalStorage("match_estates");
  const landIds = getLocalStorage("match_lands");
  isCompareBadge.value =
    (Array.isArray(estateIds) && estateIds.length > 0) ||
    (Array.isArray(landIds) && landIds.length > 0);
  eventBus.on("is-compare-badge", (val: boolean) => {
    isCompareBadge.value = val;
  });
});
</script>

<style scoped lang="scss">
.base-header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 500px;
  height: 66px;
  background-color: var(--white-monochrome);
  border-bottom: 1px solid var(--gray-monochrome-40);

  .wrapper {
    max-width: 990px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 16px;
    margin: auto;
    .logo {
      width: 164px;
      height: 20px;
      :deep(.icon) {
        width: 164px;
        height: 20px;
        svg {
          width: 164px;
          height: 20px;
        }
      }
    }
  }
  .nav-longword {
    gap: 20px;
  }
  &--nav {
    display: flex;
    align-items: center;
    gap: 24px;
    a {
      text-decoration: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      transition: scale 0.3s ease;
      &:hover {
        scale: 1.05;
      }
      &:active {
        scale: 0.95;
      }
    }
  }
  &--menu-button {
    display: none;
  }
  &--overlay {
    // display: none;
    height: 0;
    overflow: hidden;
    height: 0px;
    will-change: height;
    transition: height 0.3s ease;
  }
}

.compare {
  position: relative;
  display: flex;
  gap: 4px;
  align-items: center;
  &-badge {
    position: absolute;
    top: 1px;
    right: -5px;
    display: flex;
    width: 6px;
    height: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--green-main);
  }
}

.auth-controls {
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 24px;
  &__padding {
    padding: 8px 28px;
  }
  .user-avatar {
    cursor: pointer;
  }
}

@media (max-width: 42rem) {
  .base-header {
    min-width: unset;
    height: 100%;
    border-bottom: unset;
    .wrapper {
      height: 70px;
      padding: 19px 24px;
      border-top: 1px solid var(--gray-monochrome-40);
      border-bottom: 1px solid var(--gray-monochrome-40);
    }
    &--nav {
      display: none;
    }
    &--menu-button {
      all: unset;
      cursor: pointer;
      width: 32px;
      height: 32px;
      padding: 6px 3px;
      box-sizing: border-box;
      span,
      span::before,
      span::after {
        position: absolute;
        width: 26px;
        height: 2px;
        background-color: var(--black-monochrome);
        transition: transform 0.2s ease-in-out;
      }
      span::before {
        content: "";
        top: -10px;
      }
      span::after {
        content: "";
        top: 10px;
      }
    }
    &--menu-button.active {
      span {
        transform: rotate(45deg);
      }
      span::before {
        opacity: 0;
      }
      span::after {
        top: 0;
        transform: rotate(90deg);
      }
    }
    &--overlay.active {
      position: fixed;
      top: 70px;
      left: 0;
      width: 100dvw;
      height: calc(100dvh - 70px);
      background-color: var(--white-monochrome);
      display: flex;
    }
    &.active {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
    }
    &--popup {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 68px;
      margin: 0 auto;
      gap: 64px;
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 60px;
      padding-bottom: 32px;
      &-active {
        height: calc(100dvh - 70px);
      }

      overflow: hidden;
      height: 0px;
      will-change: height;
      transition: height 0.3s ease;

      .auth-controls {
        margin-top: auto;
        flex-direction: column;
        width: 100%;
        .base-button {
          width: 100%;
        }
      }

      a {
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
</style>
